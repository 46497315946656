.main {
    .contact-form {
        width: 100%;
        max-width: 330px;

        display: flex;
        flex-direction: column;
        gap: 20px;

        background-color: rgba(46, 56, 56, 1);

        padding: 40px 28px 40px 28px;
        box-sizing: border-box;

        position: relative;

        transition: 0.2s;

        border-radius: 20px;

        .contact-form-close {
            position: absolute;

            right: 15px;
            top: 15px;

            transition: 0.2s;

            cursor: pointer;

            img {
                width: 15px;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        .contact-form-title {
            color: #ffffff;
            font-family: SALVAGER;
            font-size: 27px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 1px;

            text-transform: uppercase;

            margin-top: 20px;
        }

        .contact-form-unit-size {
            display: flex;

            .contact-form-unit-size-label {
                color: #bdc7c7;
            }

            .contact-form-unit-size-value {
                color: #ffffff;
            }
        }

        .contact-form-electric-transformer {
            color: #CC7D61;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 18px;

            .contact-form-field {
                width: 100%;

                display: flex;
                flex-direction: column;
                gap: 5px;

                .contact-form-field-label {
                    color: #bdc7c7;

                    font-family: Inter;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                }

                .contact-form-field-input-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    width: 100%;

                    padding: 10px 12px 10px 12px;
                    box-sizing: border-box;

                    border: 1px solid rgba(255, 255, 255, 0.4);

                    transition: 0.2s;

                    &:hover {
                        border: 1px solid rgba(255, 255, 255, 0.603);
                    }

                    .contact-form-field-icon {
                        width: 18px;
                    }

                    input {
                        width: 100%;

                        background-color: transparent;
                        border: none;
                        color: #ffffff;

                        color: #e7e9de;
                        font-family: Inter;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;

                        &::placeholder {
                            color: #a8ab9c;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    // select {
                    //     -webkit-appearance: none;
                    //     -moz-appearance: none;
                    //     background: transparent;
                    //     background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                    //     background-repeat: no-repeat;
                    //     background-position-x: 100%;
                    //     background-position-y: 5px;
                    //     border: 1px solid #dfdfdf;
                    //     border-radius: 2px;
                    //     margin-right: 2rem;
                    //     padding: 1rem;
                    //     padding-right: 2rem;
                    // }
                }
            }

            .contact-form-submit {
                width: 100%;
                height: 50px;

                margin-top: 10px;

                background-color: #cc7d61;

                color: #fff;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 1px;
                text-transform: uppercase;

                cursor: pointer;

                transition: 0.2s;

                border: none;
                border-radius: 30.31px;
                box-shadow: none;

                &:hover {
                    background-color: #cc8368;
                }
            }
        }

        .contact-form-status {
            display: flex;
            flex-direction: column;
            align-items: center;

            gap: 26px;

            padding: 30px 0 40px 0;

            .contact-form-status-title {
                color: #ffffff;
                font-family: SALVAGER;
                font-size: 22px;
                font-weight: 400;
                letter-spacing: 2px;
                text-transform: uppercase;

                text-align: center;
            }

            .contact-form-status-desc {
                width: 75%;

                color: #ffffff;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 1px;
            }
        }
    }
}
