.reel {
    width: 100%;
    height: 100%;

    .reel-frame {
        width: 100%;
        height: 100%;

        .reel-menu {
            position: absolute;
            bottom: 0;

            width: calc(100% - 8px);
            height: 75px;

            display: flex;
            align-items: center;
            justify-content: center;

            // background-color: rgba(46, 56, 56, 0.5);

            right: 8px;

            transition: 0.2s ease-in-out;

            @media (max-aspect-ratio: 1.777777777777778) {
                width: calc(100%) !important;
                bottom: 8px !important;
                right: 0 !important;
            }
        }
    }
}
