.main {
    .master-plan {
        .zone-5-area {
            path {
                fill: rgba(157, 209, 173, 0.3) !important;
                stroke-width: 4 !important;
                stroke: #639d75 !important;

                &:hover {
                    fill: rgba(136, 184, 150, 0.6) !important;
                }
            }
        }

        .zone-5-icon {
            pointer-events: none !important;
        }

        .vr360 {
            ellipse {
                transition: 0.2s;
            }

            &:hover {
                ellipse {
                    fill: #bb6d51;
                }
            }
        }
    }
}
