@media screen and (max-width: 800px) {
    .modal {
        padding: 0 !important;

        .modal-exit {
            top: 20px;
            right: 20px;
        }
    }
}
