.overlay {
    width: 100vw;
    min-height: 100dvh;

    position: fixed;

    pointer-events: none;

    * {
        pointer-events: all;
    }
}
