.infobox {
    border-radius: 20px;

    .land-info {
        width: 160px;

        display: flex;
        align-items: center;
        flex-direction: column;

        .land-info-title {
            width: 100%;
            height: 40px;

            background-color: #2e3838;

            font-family: SALVAGER;
            font-size: 17px;
            color: #ffffff;
            letter-spacing: 0.5px;

            text-transform: uppercase;

            display: flex;
            align-items: center;
            justify-content: center;
        }

        .land-info-status {
            width: 100%;
            height: fit-content;
            min-height: 40px;

            font-family: SALVAGER;
            font-size: 12px;
            color: #ffffff;
            letter-spacing: 1px;

            text-transform: uppercase;

            display: flex;
            align-items: center;
            // justify-content: center;
            flex-direction: column;

            &.land-info-status-available {
                background-color: #cc7d61;
            }

            &.land-info-status-booked {
                background-color: #304a38;
            }

            &.land-info-status-sold {
                background-color: #bdc7c7;

                color: #2e3838 !important;
            }

            .land-info-status-text {
                padding: 8px 0;
            }

            .land-info-electric-transformer {
                text-transform: none;
                padding-bottom: 8px;
                font-size: 8px;
            }

            .land-info-unit-size {
                text-transform: none;
                padding-top: 8px;
                font-size: 8px;
            }
        }
    }
}
