.main {
  .services-button {
    width: 100%;
    height: 36px;

    color: #fff;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid #fff;
    border-radius: 40px;

    text-transform: uppercase;

    cursor: pointer;

    transition: background-color 0.3s ease;
    transition: color 0.3s ease;

  }
  .services-button:hover {
    background-color: #fff;
    color: #000;
  }
}
