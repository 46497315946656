@media screen and (max-width: 800px) {
    .main .overlay {
        .top-logo {
            display: none !important;
        }
    }
}

@media screen and (max-width: 800px) {
    .main .top-logo .service-button-wrapper {
        display: none !important;
    }
}

@media screen and (max-width: 800px) and (min-width: 655px) {
    .main .top-logo {
        top: auto !important;
        bottom: 75px !important;

        left: 20px !important;

        .service-button-wrapper {
            display: none !important;
        }

        img {
            width: 120px !important;
            height: 120px !important;
        }
    }
}

@media screen and (max-width: 655px) and (min-width: 352px) {
    .main .top-logo {
        top: auto !important;
        bottom: 120px !important;

        left: calc(50% - 176px) !important;

        img {
            width: 120px !important;
            height: 120px !important;
        }
    }
}

@media screen and (max-width: 352px) {
    .main .top-logo {
        top: auto !important;
        bottom: 165px !important;

        left: calc(50%) !important;
        transform: translateX(-50%) !important;

        img {
            width: 120px !important;
            height: 120px !important;
        }
    }
}
