@font-face {
    font-family: SALVAGER;
    src: url(static/fonts/SALVAGER.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Inter;
    src: url(static/fonts/Inter-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: Inter;
    src: url(static/fonts/Inter-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Inter;
    src: url(static/fonts/Inter-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: PNU;
    src: url(static/fonts/PNU-Light.ttf);
    font-weight: 300;
}
@font-face {
    font-family: PNU;
    src: url(static/fonts/PNU-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: PNU;
    src: url(static/fonts/PNU-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: Etab;
    src: url(static/fonts/etab.otf);
}

.App {
    font-family: Inter;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #e1ddd5;
    border-radius: 10px;
    cursor: default;
}

::-webkit-scrollbar-thumb {
    background: #df9174;
    border-radius: 10px;

    cursor: default;
}

::-webkit-scrollbar-thumb:hover {
    background: #d18a70;
}
