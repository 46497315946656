.main {
    .top-logo {
        position: absolute;
        left: 30px;
        top: 20px;

        pointer-events: none;

        display: flex;
        flex-direction: column;

        gap: 26px;

        img {
            width: 170px;
            height: 170px;

            pointer-events: none;
        }
    }
}
