.main {
    .svg-areas {
        svg {
            position: absolute;

            width: auto;
            height: 100%;

            @media (min-aspect-ratio: 1.777777777777778) {
                width: 100%;
                height: auto;
            }

            pointer-events: none;

            .svg-area {
                user-select: none;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;

                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;

                pointer-events: all;

                path {
                    stroke: white;
                    stroke-width: 2px;

                    fill: transparent;
                    transition: 0.4s;

                    cursor: pointer;

                    -webkit-tap-highlight-color: transparent;

                    &:hover {
                        fill: rgba(255, 255, 255, 0.75);
                    }
                }
            }
        }
    }
}
