@media screen and (max-width: 800px) {
    .main .zone-5 .services-button-wrapper {
        position: absolute;
        top: 24px;
        left: 78px;
    }
}

@media screen and (max-width: 800px) {
    .zone-5 {
        .zone-5-switches {
            left: 50% !important;
            bottom: 145px !important;

            top: auto !important;
            right: auto !important;

            transform: translateX(-50%) !important;

            flex-direction: row !important;
            gap: 4px;

            .switch {
                width: 150px !important;
            }
        }
    }
}

@media screen and (max-width: 352px) {
    .zone-5 {
        .zone-5-switches {
            flex-direction: column !important;
        }
    }
}
