.main {
    .frame {
        position: absolute;

        width: 100%;
        height: 100%;

        @media (min-aspect-ratio: 1.777777777777778) {
            overflow-x: hidden !important;
            overflow-y: auto !important;
        }

        @media (max-aspect-ratio: 1.777777777777778) {
            overflow-x: auto !important;
            overflow-y: hidden !important;
        }

        .frame-wrapper {
            position: absolute;
            z-index: 1;

            width: auto;
            height: 100%;

            @media (min-aspect-ratio: 1.777777777777778) {
                width: 100%;
                height: auto;
            }
        }

        .frame-image {
            position: absolute;

            width: auto;
            height: calc(100% + 1px);

            @media (min-aspect-ratio: 1.777777777777778) {
                width: 100%;
                height: auto;
            }

            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;

            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
        }
    }
}
