.main {
    background-color: #e1ddd5;

    .main-content {
        width: 100vw;
        height: 100dvh;

        user-select: none;

        overflow: hidden;
    }
}
