.main {
    .menu {
        position: absolute;
        left: 30px;
        bottom: 30px;

        width: 100%;
        max-width: 175px;

        padding: 17px;
        box-sizing: border-box;

        user-select: none;
        background-color: rgba(204, 125, 97, 1);

        display: flex;
        flex-direction: column;
        gap: 13px;

        border-radius: 20px;

        .menu-title {
            font-family: SALVAGER;
            font-size: 33px;
            font-weight: 400;
            line-height: 130%;
            color: rgba(242, 220, 206, 1);

            text-align: center;

            text-transform: uppercase;
        }

        .menu-buttons {
            display: flex;
            flex-direction: column;
            gap: 6px;

            margin-bottom: 12px;

            .menu-button {
                width: 100%;

                .menu-button-area {
                    width: 100%;
                    height: 37px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    padding: 0px 8px 0px 8px;
                    box-sizing: border-box;

                    font-size: 13px;
                    cursor: pointer;

                    border-radius: 1px;
                    cursor: pointer;

                    text-transform: uppercase;

                    border: 1px solid rgba(242, 220, 206, 1);

                    text-align: right !important;

                    border-radius: 40px;

                    .menu-button-area-arrow {
                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }

                &:first-of-type {
                    .menu-button-area {
                        border-top: 1px solid rgba(242, 220, 206, 1);
                    }
                }

                .menu-button-elements {
                    position: absolute;

                    width: 100%;
                    max-width: 160px;

                    background-color: rgba(0, 15, 44, 0.3);
                    backdrop-filter: blur(10px);
                    border-radius: 0 12px 12px 0;

                    padding: 20px;
                    box-sizing: border-box;

                    display: flex;
                    flex-direction: column;
                    gap: 2px;

                    .menu-button-element {
                        width: 100%;
                        height: 32px;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        padding: 0px 8px 0px 8px;
                        box-sizing: border-box;

                        font-size: 14px;
                        cursor: pointer;

                        border-radius: 1px;
                        cursor: pointer;

                        &:first-of-type {
                            border-radius: 4px 4px 1px 1px;
                        }

                        &:last-of-type {
                            border-radius: 1px 1px 4px 4px;
                        }

                        &:first-of-type:last-of-type {
                            border-radius: 4px;
                        }
                    }
                }
            }
        }

        .menu-language {
            font-family: Inter;
            font-size: 16px;
            font-weight: 400;
            line-height: 130%;
            // color: #66321f;
            color: #ffffff;

            text-align: center;
            text-decoration: none;

            // border: 1px solid #66321f;
            border: 1px solid rgba(242, 220, 206, 1);

            border-radius: 20px;
            padding: 6px 0px 6px 0px;

            cursor: pointer;

            transition: 0.2s ease-in-out;

            &:hover {
                background-color: rgba(218, 144, 118, 1);
            }
        }
    }
}
