.main {
    .percentage-loading {
        position: absolute;
        z-index: 4;

        width: 100px;
        height: 100px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: rgba(0, 15, 44, 0.8);
        // backdrop-filter: blur(10px);

        border-radius: 100%;

        bottom: 90px;
        left: calc(50%);

        transform: translateX(-50%);

        .percentage-loading-inner {
            max-width: 350px;
            width: 100%;

            padding: 40px;
            box-sizing: border-box;

            border-radius: 12px;

            display: flex;
            align-items: center;
            flex-direction: column;

            .percentage-loading-title {
                font-size: 20px;
                color: #ffffff;

                margin-top: 40px;

                text-transform: uppercase;
            }

            .percentage-loading-progress {
                font-size: 16px;
                color: #ffffff;
                margin-top: 10px;
            }
        }
    }
}
