@media screen and (max-width: 800px) {
  .main .services-button {
    background-color: rgba(0, 0, 0, 0.42);
    border: none;
    font-size: 15px;

    width: 107px;
    height: 32px;
  }
}

@media screen and (max-width: 700px) {
  .main .services .services-body {
    gap: 20px;
    justify-content: space-between;

    .service-item-name {
      max-width: 82px;
    }
  }
}

@media screen and (max-width: 450px) {
  .main .services .services-body {
    flex-wrap: wrap;
    justify-content: center;
  }
}