@media screen and (max-width: 800px) {
    .main {
        .back-button {
            z-index: 4 !important;

            width: 46px !important;
            height: 44px !important;

            top: 22px !important;
            left: 17px !important;

            border-radius: 40px;

            justify-content: center !important;

            background-color: rgba(0, 0, 0, 0.42) !important;

            img {
                width: 30px !important;
            }
        }
    }
}
