.main {
  .services {
    position: relative;
    width: 100%;
    max-width: 822px;

    display: flex;
    flex-direction: column;
    gap: 62px;

    padding: 30px 35px;
    border-radius: 20px;

    background-color: white;

    .services-close {
      position: absolute;
      right: 34px;
      top: 32px;

      cursor: pointer;

      img {
        width: 20px;
      }
    }
    .services-title {
      text-transform: uppercase;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 3%;
    }

    .services-body {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 112px;
      margin-bottom: 52px;
    }
    
    .service-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 18px;
      text-align: center;

      .service-item-icon {
        min-height: 75px;
      }
    }
  }

}